/* eslint-disable valid-typeof */
import React, { useEffect } from 'react';

// import { useStaticQuery, graphql } from 'gatsby';

// import { H1, P } from '@styles/Typography';

// import { Wrapper, Section } from '@components/Layout/styles';

// import Breadcrumb from '@components/Breadcrumb';
// import SEO from '@components/seo';
// import Sidebar from '@components/Sidebars/Main';

// import useGlobal from '@hooks/useGlobal';
// import {
// 	HIDE_APPOINTMENT_BUTTON,
// 	SHOW_APPOINTMENT_BUTTON,
// } from '@reducers/actions';
// import * as S from './_styles';

const ConveniosPage = () => {
	useEffect(() => {
		if (typeof window !== undefined) {
			window.open(
				'https://portal.privacytools.com.br/portal/2f77be0d-7368-4fbe-bd0f-d128d7617b4a/',
				'_blank'
			);
			window.history.back();
		}
	}, []);
	return <></>;

	// const [state, dispatch] = useGlobal();

	// useEffect(() => {
	// 	if (state.showAppointmentButton) {
	// 		dispatch({ type: HIDE_APPOINTMENT_BUTTON });
	// 	}

	// 	return () => {
	// 		if (!state.showAppointmentButton) {
	// 			dispatch({ type: SHOW_APPOINTMENT_BUTTON });
	// 		}
	// 	};
	// });

	// const { allCosmicjsLandingPages } = useStaticQuery(graphql`
	// 	{
	// 		allCosmicjsLandingPages(
	// 			filter: { slug: { eq: "politica-de-privacidade" } }
	// 		) {
	// 			nodes {
	// 				metadata {
	// 					title
	// 					subtitle
	// 					banner {
	// 						imgix_url
	// 					}
	// 					content
	// 				}
	// 			}
	// 		}
	// 	}
	// `);

	// const {
	// 	title,
	// 	subtitle,
	// 	banner,
	// 	content,
	// } = allCosmicjsLandingPages.nodes[0].metadata;

	// return (
	// 	<>
	// 		<SEO title={title} description={subtitle} cover={banner.imgix_url} />

	// 		<S.WrapperBanner image={banner.imgix_url}>
	// 			<Wrapper className="wrapper-banner">
	// 				<H1>{title}</H1>
	// 				<P>{subtitle}</P>
	// 			</Wrapper>
	// 		</S.WrapperBanner>

	// 		<Wrapper>
	// 			<Breadcrumb
	// 				crumbs={[
	// 					{ title: 'Portal de Privacidade', slug: 'portal-de-privacidade' },
	// 				]}
	// 			/>
	// 		</Wrapper>

	// 		<Section>
	// 			<S.Wrapper>
	// 				<S.MainContent>
	// 					<S.HTMLWrapper dangerouslySetInnerHTML={{ __html: content }} />
	// 				</S.MainContent>

	// 				<Sidebar />
	// 			</S.Wrapper>
	// 		</Section>
	// 	</>
	// );
};

export default ConveniosPage;
